<template>
    <div>

        <b-row>

            <b-col xs="12">

                <b-card no-body footer-tag="footer">
                    <b-card-header>
                        <h5 class="mb-0 text-white"><b>FILTROS</b></h5>
                    </b-card-header>
                    <b-card-body class="pt-2">
                        <b-row>

                            <b-col
                                cols="12"
                                class="mb-2"
                            >
                                <label for="text"><strong>TEXTO (NOMBRE O APELLIDO O NÚMERO DE DOCUMENTO O NÚMERO DE CELULAR)</strong></label>
                                <b-form-input
                                    id="text"
                                    v-model="filters.text"
                                />
                            </b-col>

                            <b-col
                                cols="12"
                                md="4"
                                class="mb-2"
                            >
                                <label for="supervisoryCompanies"><strong>SUBSIDIARIA(S)</strong></label>
                                <v-select
                                    id="supervisoryCompanies"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    multiple
                                    v-model="filters.supervisoryCompanies"
                                    :options="options.supervisoryCompanies"
                                    class="w-100"
                                />
                            </b-col>

                            <b-col
                                cols="12"
                                md="4"
                                class="mb-2"
                            >
                                <label for="contractingCompanies"><strong>EMPRESA(S) CONTRATISTA(S)</strong></label>
                                <v-select
                                    id="contractingCompanies"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    multiple
                                    v-model="filters.contractingCompanies"
                                    :options="options.contractingCompanies"
                                    class="w-100"
                                />
                            </b-col>

                            <b-col
                                cols="12"
                                md="4"
                                class="mb-2"
                            >
                                <label for="courses"><strong>SERVICIO(S)</strong></label>
                                <v-select
                                    id="courses"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    multiple
                                    v-model="filters.courses"
                                    :options="options.courses"
                                    class="w-100"
                                />
                            </b-col>

                            <b-col
                                cols="12"
                                md="4"
                                class="mb-2"
                            >
                                <label for="courseTypes"><strong>TIPO(S) DE SERVICIO</strong></label>
                                <v-select
                                    id="courseTypes"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    multiple
                                    v-model="filters.courseTypes"
                                    :options="options.courseTypes"
                                    class="w-100"
                                />
                            </b-col>

                            <b-col
                                cols="12"
                                md="4"
                                class="mb-2"
                            >
                                <label for="qualificationStates"><strong>ESTADO(S) DEL SERVICIO</strong></label>
                                <v-select
                                    id="qualificationStates"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    multiple
                                    v-model="filters.qualificationStates"
                                    :options="options.qualificationStates"
                                    class="w-100"
                                />
                            </b-col>

                            <b-col
                                cols="12"
                                md="4"
                                class="mb-2"
                            >
                                <label for="conditions"><strong>CONDICIÓN(ES)</strong></label>
                                <v-select
                                    id="conditions"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    multiple
                                    v-model="filters.conditions"
                                    :options="options.conditions"
                                    class="w-100"
                                />
                            </b-col>

                        </b-row>

                    </b-card-body>

                    <template #footer>

                        <b-button-loading
                            text="FILTRAR"
                            :processing="processing"
                            :block="true"
                            @process-action="getDataTable">
                        </b-button-loading>

                    </template>
                </b-card>

            </b-col>
        </b-row>

        <b-row>

            <b-col xs="12">
                <b-card>

                    <BButtonDownloadExcel :data="getDataTableExport()" :name="getExcelName()" />

                    <b-table
                        ref="table"
                        striped
                        hover
                        responsive
                        class="position-relative text-center table-report-qualifications"
                        :per-page="perPage"
                        :current-page="currentPage"
                        :items="items"
                        :fields="columns"
                        :sort-by.sync="sortBy"
                        :sort-desc.sync="sortDesc"
                        :sort-direction="sortDirection"
                        :busy="isBusy"
                        show-empty
                        empty-text="No se encontraron registros coincidentes"
                    >

                        <template #table-busy>
                            <div class="text-center text-primary my-2">
                                <b-spinner class="align-middle mb-1"></b-spinner>
                                <div><b>CARGANDO</b></div>
                            </div>
                        </template>

                        <template #head()="data">
                            <span v-html="data.label"></span>
                        </template>

                        <template #cell(state)="data">
                            <b-badge :variant="data.item.statecolor">
                                {{ data.item.statename }}
                            </b-badge>
                        </template>

                        <template #cell(coursetype)="data">
                            <b-badge :variant="data.item.coursetypecolor">
                                {{ data.item.coursetypename }}
                            </b-badge>
                        </template>

                        <template #cell(condition)="data">
                            <span v-if="data.item.approved === '-'">-</span>
                            <span v-else>
                                <b-badge variant="light-success" v-if="data.item.approved">
                                    APROBADO
                                </b-badge>
                                <b-badge variant="light-danger" v-else>
                                    DESAPROBADO
                                </b-badge>
                            </span>
                        </template>

                    </b-table>

                </b-card>
            </b-col>

        </b-row>

    </div>
</template>

<script>

    import BButtonDownloadExcel from '@core/components/b-button-download-excel/BButtonDownloadExcel.vue';
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
    import BButtonLoading from '@core/components/b-button-loading/BButtonLoading.vue';
    import { ref, onBeforeMount, onUnmounted } from '@vue/composition-api';
    import { useToast } from 'vue-toastification/composition';
    import reportStoreModule from './reportStoreModule';
    import vSelect from 'vue-select';
    import store from '@/store';
    import axios from '@axios';

    export default {
        components: {
            BButtonDownloadExcel,
            BButtonLoading,

            vSelect
        },
        setup() {

            // USE TOAST
			const toast = useToast();

            // REFS
            const options = ref({});

            const filters = ref({
                text: null,
                supervisoryCompanies: [],
                contractingCompanies: [],
                courses: [],
                courseTypes: [],
                qualificationStates: [],
                conditions: []
            });

            const items = ref([]);

            const perPage = ref(50000);
            const currentPage = ref(1);
            const sortBy = ref('');
            const sortDesc = ref(false);
            const sortDirection = ref('asc');

            const processing = ref(false);
            const isBusy = ref(false);

            const columns = ref([
                {
                    key: 'index',
                    label: 'ITEM',
                    sortable: true
                },
                {
                    key: 'student',
                    label: 'PERSONAL',
                    sortable: true
                },
                {
                    key: 'document',
                    label: 'NÚMERO DE </br> DOCUMENTO',
                    sortable: true
                },
                {
                    key: 'email',
                    label: 'CORREO </br> ELECTRÓNICO',
                    sortable: true
                },
                {
                    key: 'contracting_company',
                    label: 'EMPRESA </br> CONTRATISTA',
                    sortable: true
                },
                {
                    key: 'course',
                    label: 'SERVICIO',
                    sortable: true
                },
                {
                    key: 'coursetype',
                    label: 'TIPO DE </br> SERVICIO',
                    sortable: true
                },
                {
                    key: 'state',
                    label: 'ESTADO',
                    sortable: true
                },
                {
                    key: 'firstplaydate',
                    label: 'FECHA PRIMER </br> PLAY VIDEO',
                    sortable: true
                },
                {
                    key: 'grade',
                    label: 'CALIFICACIÓN',
                    sortable: true
                },
                {
                    key: 'condition',
                    label: 'CONDICIÓN',
                    sortable: true
                }
            ]);

            const REPORT_APP_STORE_MODULE_NAME = 'app-report';

            // REGISTER MODULE
            if (!store.hasModule(REPORT_APP_STORE_MODULE_NAME)) store.registerModule(REPORT_APP_STORE_MODULE_NAME, reportStoreModule);

            // UNREGISTER ON LEAVE
            onUnmounted(() => {
                if (store.hasModule(REPORT_APP_STORE_MODULE_NAME)) store.unregisterModule(REPORT_APP_STORE_MODULE_NAME);
            });

            onBeforeMount( async () => {
                await getSelectFilters();
            });

            // METHODS
            const getSelectFilters = async () => {
                const { data } = await axios.get('/reports/qualifications/filters');
                options.value = data.data;
            };

            const getDataTable = async () => {

                processing.value = true;
                isBusy.value = true;

                const {

                    text,
                    conditions,
                    contractingCompanies,
                    courseTypes,
                    courses,
                    qualificationStates,
                    supervisoryCompanies

                } = filters.value;

                const conditionsArray = conditions.map(item => item.value);
                const contractingCompaniesArray = contractingCompanies.map(item => item.value);
                const courseTypesArray = courseTypes.map(item => item.value);
                const coursesArray = courses.map(item => item.value);
                const qualificationStatesArray = qualificationStates.map(item => item.value);
                const supervisoryCompaniesArray = supervisoryCompanies.map(item => item.value);

                const queryParams = {
                    text,
                    conditions: conditionsArray.join(','),
                    contractingcompanies: contractingCompaniesArray.join(','),
                    coursetypes: courseTypesArray.join(','),
                    courses: coursesArray.join(','),
                    qualificationstates: qualificationStatesArray.join(','),
                    supervisorycompanies: supervisoryCompaniesArray.join(',')
                }

                store.dispatch('app-report/fetchQualifications', queryParams)
                    .then(response => {

                        const { qualifications } = response.data;

                        items.value = qualifications;
                        perPage.value = qualifications.length;

                        sortBy.value = '';
                        sortDesc.value = false;
                        sortDirection.value = 'asc';

                    })
                    .catch( (err) => {

                        const message = err.response.data.message ? err.response.data.message : 'Error al obtener la lista de las notas.';

                        toast({
                            component: ToastificationContent,
                            props: {
                                title: message,
                                icon: 'AlertTriangleIcon',
                                variant: 'danger'
                            }
                        });

                    })
                    .finally( () => {
                        processing.value = false;
                        isBusy.value = false;
                    });

            };

            const getExcelName = () => `reporte_qualificaciones-${new Date().getTime()}.xls`;

            const getDataTableExport = () => {

                const array = [];

                const rows = items.value;

                for (let a = 0; a < rows.length; a++)
                {
                    const obj = {
                        'ITEM': (a + 1),
                        'PERSONAL': rows[a]['student'],
                        'NUMERO DOCUMENTO': rows[a]['document'],
                        'CORREO': rows[a]['email'],
                        'EMPRESA CONTRATISTA': rows[a]['contracting_company'],
                        'SERVICIO': rows[a]['course'],
                        'TIPO SERVICIO': rows[a]['coursetypename'],
                        'ESTADO': rows[a]['statename'],
                        'FECHA PRIMER PLAY VIDEO': rows[a]['firstplaydate'],
                        'CALIFICACION': rows[a]['grade'],
                        'CONDICION': rows[a]['approved'] === '-' ? '-' : (rows[a]['approved'] ? 'APROBADO' : 'DESAPROBADO')
                    };

                    array.push(obj);
                }

                return array;

            };

            return {
                // REFS
                options,
                filters,
                items,
                perPage,
                currentPage,
                sortBy,
                sortDesc,
                sortDirection,
                columns,
                processing,
                isBusy,

                // METHODS
                getDataTableExport,
                getDataTable,
                getExcelName,
            }
        }
    }

</script>

<style lang="scss" scoped>

    .card-header {
        background-color: var(--primary);
        padding: 1rem 1.5rem;
    }

</style>

<style>

    .table-report-qualifications .table.b-table {
        width: 150%;
    }

</style>

<style lang="scss">
	@import '@core/scss/vue/libs/vue-select.scss';
</style>
